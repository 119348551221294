/** @jsx jsx */
import React from "react"
import { jsx } from "theme-ui"
import { Box } from "@theme-ui/components"
import kh from "./kh-logo.png"
import mj from "./mawja-logo.png"
import { Link } from "gatsby"
const Landing = () => {
  return (
    <>
      <Box mt={4}>
      <Link to={'karma-hunter'} sx={{ fontSize: [1, 2, 3],display:`inline-flex`, color: `text` }}>
      <img  src={kh} alt={'karma'}/>
      <div sx={{paddingLeft:20}}>
      <h3>Karma Hunter</h3>
      <p >Status: Live</p>
      </div>
      </Link>
      </Box>
      <Box mt={4}>
      <Link to={'mawja'} sx={{ fontSize: [1, 2, 3],display:`inline-flex`, color: `text` }}>
      <img src={mj} alt={'mawja'}/>
      <div sx={{ paddingLeft:20}}>
      <h3>mawja.io</h3>
      <p>Status: Deprecated</p>
      </div>
      </Link>
      </Box>
      </>
  )
}

export default Landing
